import React from "react"
import * as styles from "./contact.module.css"
import contact from "../data/contact.yml"

import SEO from "../components/seo"

const Contact = () => (
  <>
    <SEO title="CONTACT" description={contact.description} />

    <ul className={styles.list}>
      {contact.links.map(({ title, url }, i) => (
        <li key={i}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </li>
      ))}
    </ul>
  </>
)

export default Contact
